import { Component, OnInit, AfterViewInit, EventEmitter, ElementRef, Renderer2, ViewChild } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { RouteDataService } from '../../services/route-data.service';

import { SchematicDataService } from "../../services/schematic-data.service";
import { ComponentDataService } from '../../services/component-data.service';

import { Step } from '../../interfaces/connector-data';

// @ts-ignore
import * as d3 from 'd3';

@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.scss']
})
export class VideoViewComponent implements OnInit {
@ViewChild('myvideo') myvideo!: ElementRef;
@ViewChild('stepText') stepText!: ElementRef;

@ViewChild('stepNums') stepNums!: ElementRef;

  listener!: Function;
  currentSchematic: any;
  currentModel: any;
  viewType: string = 'video';
  componentList: any;
  svg: any;
  activeUrl: string = '';
  vidUrl = './assets/files/video/almon-crankin-circuit-basic.mp4';
  vid: string = './assets/video/crankingCircuit/basic/basic_hotspots.svg';

  public screenWidth: number = 0;
  public screenHeight: number = 0;

  isInteractive: boolean = false;
  isCustom: boolean = false;
  isStep: boolean = false;

  isOpen = true;
  zoom: any;

  step: number = 0;
  stepData: any;

  constructor(routeData: RouteDataService, private route: ActivatedRoute, private router: Router, private elRef: ElementRef, private renderer: Renderer2, private schemData: SchematicDataService, private componentService: ComponentDataService) {
    this.schemData.changeActive('main');
  }

  ngOnInit(): void {
    this.zoom = d3.zoom().scaleExtent([0.8,4]).extent([[0,0],[this.screenWidth, this.screenHeight]]).on("zoom", this.zoomed);

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // the id from route
    this.currentSchematic = this.route.snapshot.paramMap.get('id');
    // the model from route
    this.currentModel = this.route.snapshot.paramMap.get('model');

    this.idCheck(this.currentSchematic);


    this.schemData.changeModel(this.currentModel);

    this.schemData.changeUrl(this.currentModel,this.currentSchematic,'video');
    this.schemData.currentUrl.subscribe(current => this.activeUrl = current);


    this.componentService.setStepData(this.currentModel);

    this.schemData.setViewType(this.viewType);
    this.componentService.stepContent.subscribe(steps => {this.stepData = steps;
    console.log(this.stepData.text)});

    console.log(this.stepData['text'], this.componentList);

    //this.componentService.setComponentData(this.currentModel);
    // this.componentService.getComponentData().subscribe(connectors => this.componentList = connectors);

    this.setVideoUrl();
    if(this.isInteractive){
        this.vid = this.activeUrl + '/'+ this.currentSchematic + '_hotspots.svg';
        console.log(this.vid);
        this.createImage(this.vid);
    }

  }

  ngAfterViewInit() {
    const target = this.elRef.nativeElement.querySelector('#schematic-container');
    this.listener = this.renderer.listen(target, 'click', this.test.bind(this));
  }

  public zoomed({transform}:any){
    console.log(transform);

    d3.select('myVid').attr("transform", transform);
    //d3.select('svg').attr("transform", transform);
  }

  //opens and closes the sidebar
  toggleSB(){
    this.isOpen = !this.isOpen;
    let sb = document.getElementById('sb-content');
    if(sb != null && !this.isOpen){
      sb.classList.add('closed');
    } else if(sb != null && sb.classList.contains("closed"))  {
      sb.classList.remove("closed")
    }
  }


  idCheck(id: string){
    console.log(this.currentSchematic, id);
    var curr, cId;
    if(this.currentSchematic != null){
      curr = id.split("-");
      this.currentSchematic = curr[0];
      cId = curr[1];
      if (cId != null){

        switch(cId){
          case 'custom': {
            this.isCustom = true;
            break;
          }
          case 'step': {
            this.isStep = true;
            //get step info here
            break;
          }
          default: {
            this.isInteractive = true;
            break;
          }
        }

      }

        // curr[1] === 'custom' ? this.isCustom = true : this.isCustom = false;

//         curr.length > 1 && !this.isCustom ? this.isInteractive = true : this.isInteractive = false;
// console.log(this.isInteractive);

    }
    this.schemData.changeId(this.currentSchematic);
    var vidClass = this.isInteractive ? this.currentSchematic + "-plus" : this.currentSchematic;
    var test = this.componentList;
    this.svg = d3.select("#schematic-container")
    .attr('class', vidClass);
  }

  public createImage(imgUrl: string) {
    console.log(imgUrl);
    var vidClass = this.isInteractive ? this.currentSchematic + "-plus" : this.currentSchematic;
    var test = this.componentList;
    this.svg = d3.select("#schematic-container")
    .attr('width', this.screenWidth)
    .attr('height', this.screenHeight)
    .attr('class', vidClass)
    .call(this.zoom);

    d3.svg(imgUrl).then((d: any) => {
         this.svg.node().prepend(d.documentElement);
         // var item = d3.select('#Components').selectAll('rect');
         //   item.each(function(d:any,i: number,nid: any){
         //
         //     for(var j = 0; j < test.length; j++){
         //         if(test[j].id === nid[i].id){
         //           var t = test[j].name;
         //           var s = d3.select("#" + nid[i].id).attr('data-title', t);
         //
         //         }
         //       }
         //
         //
         //   });
    });

    // var item = d3.select('#Components').selectAll('rect');
    // console.log(item);
    //   item.each(function(d:any,i: number,nid: any){
    //     console.log(d, i, nid);
    //       // for(var i = 0; i < test.length; i++){
    //       //   if(nid === test[i].id){
    //       //     var t = test.name;
    //       //     d3.select("#" + item).append("svg:title").text(function(d){return t});
    //       //     console.log(test.name);
    //       //   }
    //       // }
    //
    //
    //   });


    // for(var i = 0; i < this.componentList.length; i++){
    //   if(item === this.componentList[i].id){
    //     var t = this.componentList[i].name;
    //     d3.select("#" + item).append("svg:title").text(function(d){return t});
    //     console.log(this.componentList[i].name);
    //   }
    // }


  }

  // gets called from sidebar
  updateImg(e: any){

    var selectedEle = d3.select('#' + e).node();
    this.update(selectedEle);
  }

  test(e: any):void {
    console.log(e.target.parentNode.id);
    //console.log(e.target.tagName, this.lineGroup);
    var item = e.target.id;
    var itemId = e.target.parentNode.id;
    console.log(item, itemId);
    if(item != null){
      if ( itemId === "Components"){
        this.componentService.setConnector(item);
        this.componentService.setContent(item);
        this.schemData.changeActive(item);
      }




    var t = e;
    this.update(t);
  // }else if (item != null && itemId === "schematic-container"){

  }

  }

  public update(t: any):void {
    var selectedItem;
    if(t.type === "click"){
      selectedItem = t.target;
    } else {
      selectedItem = t;
    }
    console.log(selectedItem);

    var ele = selectedItem.id;
    console.log(ele);
      let current: Array<string> = [];
    let nodel: any;
    let nodels = ['circle', 'rect', 'g'];

        current.push(selectedItem.id);
        nodel = d3.select('svg').selectAll('g');

      nodel.each(function(d:any,i: number,nid: any){
        let resetClass = d3.select(nid[i]).selectAll('*');
        resetClass.each(function(d: any, i:number, rid: any){
          d3.select(rid[i]).classed("test", false);

        });
      });


        if(current.length > 0){
            for(var i = 0; i < current.length; i++){
              console.log(current[i]);
              d3.select('#' + current[i]).classed("test", true);

            }
        }

  } //end UPDATE

  setVideoUrl(){
    this.vidUrl = this.activeUrl + '/almon-cranking-circuit-'+ this.currentSchematic + '.mp4';
  }

  playVideo(startTime: any, endTime: any) {



    var myvideo = this.myvideo.nativeElement;
      console.log(this.myvideo.nativeElement, this.stepData.startTime, endTime);


    function checkTime() {
        if (myvideo.currentTime >= endTime) {
           myvideo.pause();
        } else {
           /* call checkTime every 1/10th
              second until endTime */
           setTimeout(checkTime, 100);
        }
    }

    /* stop if playing (otherwise ignored) */
    myvideo.pause();
    /* set video start time */
    myvideo.currentTime = startTime;
    /* play video */
    myvideo.play();
    /* check the current time and
       pause IF/WHEN endTime is reached */
    checkTime();
}

  buttonEvent(e: any) {
    /* get the id of the clicked button */
    console.log(e.target.tagName)
    var element_id = e.target.id;
    if(e.target.tagName === "IMG"){
      element_id = e.target.parentNode.id;
    }
    //var element_id = e.target.id;
    console.log(this.isStep, this.isCustom, element_id);
    /* E.G. element_id = 'playme', 'jump', or 'jump2' */
    var total = this.stepData.length -1;
    /* declare variables before setting them */
    var timeStart = 0;
    var timeEnd = 0;
    if(this.isStep){
      element_id = e.target.parentNode.id;
      switch(element_id) {
          case 'first':
            this.step = 0;

              break;
          case 'previous':
            if(this.step > 0 && this.step != 1 ){
              this.step -= 1;
            }else{
              return;
            }

              break;
          case 'next':
          this.step +=1;
              break;
          case 'replay':
            this.step = this.step;

      }
      var text = this.stepData.find((obj:any) => {
        return obj.step == this.step;
      });
      //console.log(text);
      this.stepText.nativeElement.innerHTML = text.text;
      this.step === 0 ? this.stepNums.nativeElement.innerHTML = '---' : this.stepNums.nativeElement.innerHTML = this.step + ' / ' + total;
      // this.componentService.setStepData(this.currentModel, this.step);
      console.log(text.text);
      this.playVideo(text.timeStart, text.timeEnd);
    }



    /* set start and end values depending
       on which button was clicked */
     else if(this.isCustom){
    switch(element_id) {
        case 'systemInit':
            /* example values... */
            timeStart = 0;
            timeEnd = 2;
            break;
        case 'start':
            /* example values... */
            timeStart = 2;
            timeEnd = 11;
            break;
        case 'neutral':
            timeStart = 11;
            timeEnd = 15;
            break;
        case 'park':
            timeStart = 16;
            timeEnd = 30;
    }
    /* call 'playVideo()' */
    this.playVideo(timeStart, timeEnd);
  } else {
    console.log("play it")
    switch(element_id) {
        case 'play':
            /* example values... */
            timeStart = 0;
            timeEnd = 68;
            break;
        case 'pause':
            /* example values... */
            this.myvideo.nativeElement.pause();
            break;

    }
    if (element_id != 'pause'){
      console.log(this.myvideo.nativeElement.duration,timeStart, timeEnd);
      this.playVideo(timeStart,timeEnd);
    }

  }



  }


}
