<div class="help active">
  <div class="interactions">
    <div class="title" data-lang-child="interactions">Interactions</div>
    <div class="interact-info help-info-box">
      <div class="help-row">
        <div class="help-row-img zoom"></div>
        <div class="help-row-text first">When hovering over the schematic, rotating the <span class="highlight">Middle Wheel</span> will zoom the schematic in and out.</div>
      </div>

      <div class="help-row">
        <div class="help-row-img minus"></div>
        <div class="help-row-text">Click the <span class="highlight">Minus Button</span> to zoom out of the schematic.</div>
      </div>

      <div class="help-row">
        <div class="help-row-img plus"></div>
        <div class="help-row-text">Click the <span class="highlight">Plus Button</span> to zoom into the schematic.</div>
      </div>

      <div class="help-row">
        <div class="help-row-img pan"></div>
        <div class="help-row-text">Click and drag the <span class="highlight">Left Mouse Button</span> to move the schematic.</div>
      </div>

      <div class="help-row">
        <div class="help-row-img pull"></div>
        <div class="help-row-text">Click the <span class="highlight">Handle</span> to slide the side panel in and out of view.</div>
      </div>

      <div class="help-row">
        <div class="help-row-img legend"></div>
        <div class="help-row-text">Click the <span class="highlight">Header</span> to expand or collapse the line type legend.</div>
      </div>
    </div>
  </div>

  <div class="views">
    <div class="title">Views</div>
    <div class="view-info help-info-box">
      <div class="section-title">side panel</div>
      <div class="help-text">Displays information about the component</div>

      <div class="help-text">Select a tab to display different information in the panel:</div>

      <div class="section-title">Harness view</div>
      <div class="help-text">In the <span class="highlight">Harness View</span> a view of the component's location on the model is highlighted</div>

      <div class="help-text">Selecting a connector from the components list will highlight the area on the model that the connector is located</div>

      <div class="help-text">Clicking a connector on the model will highlight the connector and select the component in the side panel</div>

      <div class="section-title">Schematic view</div>
      <div class="help-text">In the <span class="highlight">Schematic View</span>, a view of the component's location on the schematic is displayed</div>

      <div class="help-text">Selecting a connector from the component list will highlight the area on the schematic where the component is located</div>

      <div class="help-text">Selecting a component on the schematic will highlight it and select it on the component side panel</div>



      <div class="section-title">wire view</div>
      <div class="help-text">Selecting a wire on the schematic will gray out all other wires and highlight all components that are connected to that wire</div>

      <div class="help-text">A list of connected components will be displayed on the side panel and can be selected to center the schematic on that component</div>

    </div>
  </div>
</div>