<div class="sb-header" *ngIf="id">
  <h3>{{id}} Information</h3>
</div>
<!-- <div class="sb-header" *ngIf="selectedConnector">
  <h3>{{selectedConnector.name}} Information</h3>
</div> -->
<div id="sb-view">
  <div *ngIf="viewType==='schematics'" (click)="toggleImage()" class="thumb-view">
    <h5 id="thumbView">Click for {{view}} View</h5>
    <div id="view-container">
      <img id="thumbBase" class="base" src="{{activeUrl}}/{{view}}/{{activeComponent}}.jpg" alt="{{activeComponent}} image">
    </div>

  </div>
  <div *ngIf="viewType==='video'" class="thumb-view">
    <div id="view-container">
      <img id="thumbBase" class="base" src="{{activeUrl}}/components/{{activeComponent}}.png" alt="{{activeComponent}} image">
    </div>

  </div>
</div>
<div id="sb-tabs">
  <app-tabs>
    <app-tab [tabTitle]="'Components'">
      <app-component-menu [connector]="connector" (openCompInfo)="onSelectComp($event)"></app-component-menu>
    </app-tab>
  </app-tabs>
  <ng-template let-component="component" #compInfo>
    <app-component-info></app-component-info>
  </ng-template>
  <ng-template let-component="component" #compPinout>
    <app-component-pinout></app-component-pinout>
  </ng-template>
  <ng-template #compGroup>
    <app-component-group (openCompInfo)="onSelectComp($event)"></app-component-group>
  </ng-template>

  <ng-template let-component="component" #compContent>
    <app-component-content></app-component-content>
  </ng-template>

  <ng-template #help>
    <app-component-help></app-component-help>
  </ng-template>
</div>