import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

import { Connector, Info } from "../../interfaces/connector-data";
import { ComponentDataService } from '../../services/component-data.service';
import { SchematicDataService } from "../../services/schematic-data.service";

@Component({
  selector: 'app-component-info',
  templateUrl: './component-info.component.html',
  styleUrls: ['./component-info.component.scss']
})
export class ComponentInfoComponent implements OnInit {

  @Input() name: any;

  componentInfo: any;

  activeComponent: any;

  activeUrl: string = '';

  constructor(private componentService: ComponentDataService, private schemData: SchematicDataService) {
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.componentService.connInfo.subscribe(info => {
       this.componentInfo = info;
    });

    this.schemData.currentUrl.subscribe(current => this.activeUrl = current);
  }

  ngOnInit(): void {}
  ngAfterViewInit(){}






}
