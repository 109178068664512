import { Component, OnInit, AfterViewInit, ViewChild, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

import { SchematicDataService } from '../../services/schematic-data.service';
import { ComponentDataService } from '../../services/component-data.service';
import { Connector } from '../../interfaces/connector-data';
import { Tab } from '../../interfaces/tab';
import { TabComponent } from '../tabs/tab.component';
import { TabsComponent } from '../tabs/tabs.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() imgEle = new EventEmitter();

  @Input() clearSidebar: any;

  //324 add for dynamic Tabs
  @ViewChild(TabsComponent) tabsComponent: any;
  @ViewChild('compInfo') getCompInfo: any;
  @ViewChild('compPinout') getPinout: any;
  @ViewChild('compGroup') getCompGroup: any;
  @ViewChild('compContent') getContent: any;
  @ViewChild('help') getHelp: any;

  image: string = '';
  imgElement = "./assets/defender/hd5/schematic/main.jpg";
  view: string = 'schematic';
  model: string = '';
  id: string = '';


  activeComponent: any;

  activeType: any;
  viewType: string = '';
  activeConnImg: string = '';
  activeImage: string = '';
  activeUrl: string = '';
  activeGroup: Connector[] = [];

  connectors: Connector[] = [];
  connector: Connector[] = [];

  selectedConnector: any;

  constructor(private schemData: SchematicDataService, private componentService: ComponentDataService) {

   }

  ngOnInit(): void {

    this.schemData.currentModel.subscribe(model => this.model = model);
    this.schemData.currentId.subscribe(id => this.id = id);
    this.schemData.currentImg.subscribe(image => this.image = image);
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.schemData.currentType.subscribe(current => this.activeType = current);
    this.schemData.viewType.subscribe(current => this.viewType = current);

    console.log(this.viewType);

    this.schemData.currentConnImg.subscribe(current => this.activeConnImg = current);

    this.componentService.setComponentData(this.model);
    this.componentService.getComponentData().subscribe(connectors => this.connector = connectors);
    this.componentService.connView.subscribe(connectors => this.selectedConnector = connectors);

    this.schemData.currentUrl.subscribe(current => this.activeUrl = current);

    this.componentService.groupInfo.subscribe(components => this.activeGroup = components);
    console.log(this.activeUrl, this.view, this.activeComponent);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initHelp();
    });

  }

  ngOnChanges(changes:{ [property: string]: SimpleChanges}){
    console.log(changes, this.clearSidebar);
    console.log(this.selectedConnector, this.activeComponent);

    if(this.clearSidebar){
      if(this.clearSidebar === 'group'){
          this.tabsComponent.closeTabs();
        this.tabsComponent.openTab(`Info`, this.getCompGroup, this.activeGroup, true);
        this.tabsComponent.openTab(`Help`, this.getHelp, {}, true);
      } else {
        this.createTabs(this.selectedConnector);
      }

    } else {
      if(this.tabsComponent){
        this.tabsComponent.closeTabs();
        this.initHelp();
      }
    }
  }

  onSelectComp(connector: Connector){
    console.log(connector)
    this.componentService.setConnector(connector.id);
      this.schemData.changeActive(connector.id);
    if(this.viewType === 'schematics') {
      this.componentService.setInfo(connector);
      this.componentService.setPinout(connector);
    }
    this.createTabs(connector);
    console.log(this.activeComponent, this.selectedConnector);
    this.imgEle.emit(connector.id);
  }


  createTabs(data: any) {
    console.log(data.name, this.viewType);
      this.tabsComponent.closeTabs();
    if(data && this.viewType){
      switch(this.viewType){
        case 'video': {
          console.log(this.getContent);
          this.tabsComponent.openTab(`Content`, this.getContent, data, true);
          break;
        }
        case 'schematics': {


          this.tabsComponent.openTab(`Info`, this.getCompInfo, data, true);
          this.tabsComponent.openTab( `Pinout`, this.getPinout, data, true);
          break;
        }
        case 'componentId': {
          console.log("coponent Id data needed");
          break;
        }
        default: {
          break;
        }
      }
    }
    // this.tabsComponent.openTab(`Info`, this.getCompInfo, data, true);
    // this.tabsComponent.openTab( `Pinout`, this.getPinout, data, true);
    this.tabsComponent.openTab(`Help`, this.getHelp, data, true);
  }

  initHelp(){
    this.tabsComponent.openTab(
      `Help`,
      this.getHelp,
      {},
      true
    );
  }

  initGroup(){
    this.tabsComponent.openTab(
      `Info`,
      this.getCompGroup,
      {},
      true
    );
  }

  toggleImage():void {
    console.log(this.activeType);
    var imgElement = document.getElementById('thumbBase');
    this.activeType = (this.activeType === 'schematic')? 'ghost' : 'schematic';
    this.view = (this.activeType === 'ghost')?'schematic':'ghost';

    //this swaps main view
    var img1 = this.activeUrl+"/schematic/main.jpg",
    img2 = this.activeUrl+"/ghost/main.jpg";

    if(!this.activeComponent){
      this.imgElement = (this.imgElement === img1)? img2 : img1;
      this.image = (this.image === img1)? img2 : img1;
    } else {
      this.imgElement = this.activeUrl+'/'+this.view+'/' + this.activeComponent + '.jpg';

      this.image = (this.image === img1)? img2 : img1;
    }
    console.log(this.imgElement, this.activeComponent);
    this.schemData.changeType(this.activeType);
    this.schemData.changeImage(this.image);
    this.schemData.changeUrl(this.model, this.id);
    this.schemData.changeConnImage(this.activeComponent);
  }

}
