import { Component, OnInit, AfterViewInit, HostListener, Input, Output, EventEmitter, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

// @ts-ignore
import * as d3 from 'd3';

import { SchematicDataService } from "../../services/schematic-data.service";
import { ComponentDataService } from '../../services/component-data.service';
import { RouteDataService } from '../../services/route-data.service';

import { SidebarComponent } from '../../components/sidebar/sidebar.component';

@Component({
  selector: 'app-schematics-view',
  templateUrl: './schematics-view.component.html',
  styleUrls: ['./schematics-view.component.scss']
})
export class SchematicsViewComponent implements OnInit {
  // @Output() setActive: EventEmitter<string> = new EventEmitter<string>();
  // @Input() active: any;

  //@Output() clearSide = new EventEmitter<any>();
  clearSide: any = '';
  viewType: string = 'schematics';
  currentSchematic: any;
  currentModel: any;
  //model!: Observable<any>;

  image: string = '';
  activeComponent: any;
  activeType: any;
  activeUrl: string = '';
  isOpen = true;

  public screenWidth: number = 0;
  public screenHeight: number = 0;

  svg: any;
  zoom: any;

  listener!: Function;

  connector: any;
  lineGroup: any;

  constructor(private componentService: ComponentDataService,private schemData: SchematicDataService, private elRef: ElementRef, private renderer: Renderer2, routeData: RouteDataService, private route: ActivatedRoute, private router: Router) {

    this.schemData.changeActive('main');
    // this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.schemData.setViewType(this.viewType);

    this.schemData.currentType.subscribe(current => this.activeType = current);
   }

  ngOnInit(): void {


    this.zoom = d3.zoom().scaleExtent([0.8,4]).extent([[0,0],[this.screenWidth, this.screenHeight]]).on("zoom", this.zoomed);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // the id from route
    this.currentSchematic = this.route.snapshot.paramMap.get('id');
    this.schemData.changeId(this.currentSchematic);

    // the model from route
    this.currentModel = this.route.snapshot.paramMap.get('model');
    this.schemData.changeModel(this.currentModel);

    this.schemData.changeType('ghost');
    //this.schemData.changeActive('main');


    this.schemData.currentActive.subscribe(current => this.activeComponent = current);

    this.componentService.groupInfo.subscribe(groupInfo => {this.lineGroup = groupInfo; //console.log(this.lineGroup);
    });

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.schemData.changeUrl(this.currentModel,this.currentSchematic,"schematics");

    this.schemData.currentUrl.subscribe(current => this.activeUrl = current);

    this.schemData.changeImage(this.activeUrl +'/'+this.activeType+'/main.svg');

    this.schemData.currentImg.subscribe(image =>
      {
        this.image = image;
        d3.select("svg").remove();
        this.createSVG();
      });


}

  @HostListener('window:resize', ['$event'])
  onresize(event: any) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  // gets called from sidebar
  updateImg(e: any){
    var selectedEle = d3.select('#' + e).node();
    this.update(selectedEle);
    console.log(e, selectedEle);
  }

  test(e: any):void {
    // //console.log(d3.select(e.target).data(data));
    //console.log(e.target.tagName, this.lineGroup);
    var item = e.target.tagName;
    var itemId = e.target.id;
    console.log(itemId);
    if(!this.isOpen){
      e.preventDefault();
    }

    if (item === "rect" || item === "circle"){
      this.componentService.setGroupData('');
      this.clearSide = e.target.id;
      this.componentService.setConnector(e.target.id);
      this.schemData.changeActive(e.target.id);
      this.componentService.setInfo(e.target.id);
      this.componentService.setPinout(e.target.id);
      if(!this.isOpen){
        this.toggleSB();
      }
    } else if (item === "line" || item === "polyline"){
      this.clearSide = "group";
      this.schemData.changeActive("main");
      if(!this.isOpen){
        this.toggleSB();
      }
      //console.log(this.lineGroup);
    } else if(itemId.length === 0){
          //tell sidebar to close all tabs
          this.clearSide = '';

          this.schemData.changeActive('main');
    }


    var t = e;
    this.update(t);
  }

  ngAfterViewInit() {
    const target = this.elRef.nativeElement.querySelector('#schematic-container');
    this.listener = this.renderer.listen(target, 'click', this.test.bind(this));

  }

  //opens and closes the sidebar
  toggleSB(){
    this.isOpen = !this.isOpen;

    let container = document.getElementById('schematic-container');
    let sb = document.getElementById('sb-content');
    if(sb != null && container != null && !this.isOpen){
      sb.classList.add('closed');
              container.classList.add('fullWidth');
    } else if(sb != null && container != null && sb.classList.contains("closed"))  {
      sb.classList.remove("closed");
              container.classList.remove('fullWidth');
    }
  }

  //draws schmatic image in schematic container
  private createSVG(): void {
    d3.select("svg").remove();
    var sWidth = this.screenWidth;
    var sHeight = this.screenHeight;
   this.svg = d3.select('#schematic-container');

   d3.select("svg").remove();

   //this.componentService.getComponentData().subscribe(connectors => this.connector = connectors);

        var imgUrl =  this.image.slice(0, -4) + '.svg';
        this.createImage(imgUrl);



}
public createImage(imgUrl: string) {
  d3.select("svg").remove();
  console.log(imgUrl);
  this.svg = d3.select("#schematic-container")
  .attr('width', this.screenWidth)
  .attr('height', this.screenHeight)
  .call(this.zoom);

  d3.svg(imgUrl).then((d: any) => {
    this.svg.selectAll('svg').remove();
       this.svg.node().append(d.documentElement);
       if(this.activeComponent.length > 0){
         d3.select("#" + this.activeComponent).classed("test", true);
       }
  })
  //d3.select('#Layer_1').
}


  public zoomed({transform}:any){
    //console.log(transform.invert);

    d3.select('#Layer_1').attr("transform", transform);
    d3.select('#Components').attr("transform", transform);
    d3.select('#Wires').attr("transform", transform);
    d3.select('#Background').attr("transform", transform);
    d3.select('#Base').attr("transform", transform);
    d3.select('#Text').attr("transform", transform);
  }

public update(t: any):void {
  var selectedItem;
  if(t.type === "click"){
    selectedItem = t.target;
  } else {
    selectedItem = t;
  }

  var ele = selectedItem.tagName;
  let current: Array<string> = [];
  let nodel: any;
  let nodels = ['circle', 'rect', 'g'];
  if(ele === 'line' || ele === 'polyline') {
      current.push(selectedItem.parentNode.id);
      nodel = d3.select('#' + selectedItem.parentNode.parentNode.id).selectAll('g');
      let rects = d3.select('#Components').selectAll('rect');

      let lines = d3.select('#' + selectedItem.parentNode.id).selectAll('*');
      if(lines != null && rects != null) {
      lines.each(function(d:any,i: number, l: any){
        if(l[i]){
          let r1 = l[i].getBoundingClientRect();
console.log(r1);
          rects.each(function(d:any,i: number, r: any){
            if(r[i]){
              let r2 = r[i].getBoundingClientRect();
              //checks if lines link with component boxes - crosses 1 side
              if(!(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top)) {
                //checks if line intersects component  - crosses 2 sides of component or is too close to highlighted line
                if(!((r2.left > r1.left && r2.right < r1.right) || (r2.top > r1.top && r2.bottom < r1.bottom))) {
                  if(current.indexOf(r[i].id) === -1){
                    current.push(r[i].id);
                  }
                }
               }
            }
          }); // end rect.each
        }
      }); // end lines.each
    } // end if not null
    console.log(current);
      this.buildConnectedMenu(current);

  } else {
    if(selectedItem.id.length > 0){
      current.push(selectedItem.id);
      nodel = d3.selectAll(ele);
    }

  }
  // removes class from all
  for( var i = 0; i < nodels.length; i++){
      let base = d3.select('#Base');
        if(nodels[i] === 'g'){
          nodel = d3.select('#Wires').selectAll(nodels[i]);
          wires(nodel);
        } else if(nodels[i] === 'circle') { //remove this when model svg is updated
          nodel = d3.selectAll(nodels[i]);
          reset(nodel);
        } else if(nodels[i] === 'rect'){
          nodel = d3.select('#Components').selectAll(nodels[i]);
          reset(nodel);
        }

      }
      function wires(n: any){
        reset(nodel);
        n.each(function(d:any,i: number,nid: any){
          //console.log(nid[i]);
          d3.select(nid[i]).classed("test", false);
          d3.select(nid[i]).classed("dimmed", false);
          if(current.length > 0){
            for(var j = 0; j < current.length; j++){
              //console.log(nid[i].id, current[j]);
              if(nid[i].id === current[j]){

                d3.select(nid[i]).classed("test", true);
                break;
              } else {
                d3.select(nid[i]).classed("dimmed", true);
              }



              }
          }
        });
      }

      function reset(nodel: any){
        nodel.each(function(d:any,i: number,nid: any){
          d3.select(nid[i]).classed("test", false);
          d3.select(nid[i]).classed("dimmed", false);
        });
      }

      if(current.length > 0){
          for(var i = 0; i < current.length; i++){
            d3.select('#' + current[i]).classed("test", true);

          }
      }

} //end UPDATE



public buildConnectedMenu(current: any): void {
  this.componentService.setGroupData(current);
}

public zoomOut(){
  console.log('zoom out');
  this.zoom.scaleBy(this.svg, 1/1.3);
  //d3.select('#Layer_1').transition().call(this.zoomed.scaleBy, 0.5);
  //d3.select('#Components').transition().call(this.zoom.scaleBy, 0.5);
}

public zoomIn(){
  console.log('zoom In');

  this.zoom.scaleBy(this.svg, 1.3);
  //d3.select('#Layer_1').transition().call(this.zoomed.scaleBy, 1.5);
  //d3.select('#Components').transition().call(this.zoom.scaleBy, 1.5);
}

public zoomCenter(){
  // d3.select('#Layer_1').transition().duration(750).call(
  //   this.zoomed,
  //   d3.zoomIdentity,
  //   d3.zoomTransform(this.svg.node()).invert([this.screenWidth/2, this.screenHeight/2])
  // );
  d3.select('#Components').transition().duration(750).call(
      this.zoomed,
      d3.zoomIdentity,
      d3.zoomTransform(this.svg.node()).invert([this.screenWidth/2, this.screenHeight/2])
    );
  console.log(event, 'Re-centered');
}


}
