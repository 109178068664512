<div class="wrapper">
  <div class="left">
    <div id="logo">
      <img src="./assets/images/almon-logo.png" alt="Almon Logo">
    </div>
  </div>
  <div class="right">
    <div id="home-title">
      <h2>Interactive </h2>
      <h2 class="bold-title">Schematics</h2>
    </div>
    <ul>
      <!-- <li class="home-nav-button"><a [routerLink]="['/schematics/defender/hd5']">HD5</a></li> -->
      <li class="home-nav-button"><a [routerLink]="['/schematics/ryker/ryker-2019']">Interactive</a></li>
      <li class="home-nav-button"><a [routerLink]="['/componentId/planetaryGear']">Component ID</a></li>
      <li class="home-nav-button"><a [routerLink]="['/video/crankingCircuit/basic']">Animated</a></li>
    </ul>
  </div>
</div>