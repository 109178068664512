<!-- <ul>
  <li *ngFor="let tab of tabs" [ngClass]="{active: tab.selected}">
    <span (click)="selectTab(tab)">{{tab.tabText}}</span>
  </li>
</ul>
<div class="tab-content">
  <ng-content></ng-content>
</div> -->

<ul>
  <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active]="tab.active">
    {{tab.title}}
  </li>
  <!-- dynamic tabs -->
  <li *ngFor="let tab of dynamicTabs" (click)="selectTab(tab)" [class.active]="tab.active">{{tab.title}}
  </li>

</ul>
<ng-content></ng-content>
<ng-template #dynamic></ng-template>