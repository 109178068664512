import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wirePipe'
})
export class WireColorPipe implements PipeTransform {

  transform(value: string): string {
    let newStr: string = '';
    newStr = value.split('-')[0].toLowerCase().replace(/\/|\s/g, "");
    return newStr;
  }


}
