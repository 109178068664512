import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { SchematicDataService } from '../../services/schematic-data.service';
import { ComponentDataService } from '../../services/component-data.service';
import { Connector } from '../../interfaces/connector-data';

@Component({
  selector: 'app-comp-id-sidebar',
  templateUrl: './comp-id-sidebar.component.html',
  styleUrls: ['./comp-id-sidebar.component.scss']
})
export class CompIdSidebarComponent implements OnInit {
  @Output() imgEle = new EventEmitter();

  view: string = 'componentId';
  activeComponent: any;
  connector: Connector[] = [];
  selectedConnector: any;
  model: string = '';

  constructor(private schemData: SchematicDataService, private componentService: ComponentDataService) { }

  ngOnInit(): void {
    this.schemData.currentModel.subscribe(model => this.model = model);
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.componentService.setComponentData(this.model);
    this.componentService.getComponentData().subscribe(connectors => this.connector = connectors);
    this.componentService.connView.subscribe(connectors => this.selectedConnector = connectors);
  }


    onSelectComp(connector: Connector){
      console.log(connector);
      this.componentService.setConnector(connector.id);
      this.schemData.changeActive(connector.id);
      console.log(this.activeComponent, this.selectedConnector);
      this.imgEle.emit(connector.id);
    }

}
