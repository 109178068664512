<div class="title">Stuff</div>
<div *ngIf="pinoutInfo" class="comp-data">

  <!-- <h2>{{name}}</h2> -->
  <img class=" connector" src="{{activeUrl}}/pin-out-imgs-back/{{activeComponent}}.jpg" alt="Connector front view">
  <table class="table table-striped">
    <thead>
      <th>Pin</th>
      <th>Function</th>
      <th>Wire Color</th>
    </thead>
    <tbody>
      <tr *ngFor="let info of pinoutInfo">
        <td>{{ info.pin }}</td>
        <td>{{ info.rep }}</td>
        <td>{{ info.wireColor }} <span class='wired {{info.wireColor | wirePipe }}'></span></td>
      </tr>
    </tbody>
  </table>
  <img class="connector" src="{{activeUrl}}/pin-out-imgs-front/{{activeComponent}}.jpg" alt="Connector rear view">
</div>