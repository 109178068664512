import { Component, OnInit, AfterViewInit, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { RouteDataService } from '../../services/route-data.service';
// @ts-ignore
import * as d3 from 'd3';

import { SchematicDataService } from "../../services/schematic-data.service";
import { ComponentDataService } from '../../services/component-data.service';


@Component({
  selector: 'app-comp-id',
  templateUrl: './comp-id.component.html',
  styleUrls: ['./comp-id.component.scss']
})
export class CompIdComponent implements OnInit {
  listener!: Function;
    isOpen = true;
    imgUrl = './assets/planetaryGear/planetary-gear.svg';
    activeComponent: any;
    svg: any;
      currentModel: any;
      currentSchematic: any;

    viewType: string = 'compId';

    public screenWidth: number = 0;
    public screenHeight: number = 0;


  constructor(private componentService: ComponentDataService, private schemData: SchematicDataService, private elRef: ElementRef, private renderer: Renderer2, routeData: RouteDataService, private route: ActivatedRoute, private router: Router) {

   }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
            this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.createImage(this.imgUrl);
    // the id from route
    this.currentSchematic = this.route.snapshot.paramMap.get('id');
    this.schemData.changeId(this.currentSchematic);

    // the model from route
    this.currentModel = this.route.snapshot.paramMap.get('model');
    console.log(this.currentModel);
    this.schemData.changeModel(this.currentModel);

    this.schemData.changeUrl(this.currentModel, this.currentSchematic, 'componentId');


    this.schemData.setViewType(this.viewType);

console.log(this.currentSchematic, this.currentModel);
  }

  // gets called from sidebar
  updateImg(e: any){
    console.log(e);
    var selectedEle = d3.select('#' + e).node();
    this.update(selectedEle);
        console.log(e, selectedEle);
  }

  public createImage(imgUrl: string) {
    console.log(imgUrl);
    this.svg = d3.select("#schematic-container")
    .attr('width', this.screenWidth)
    .attr('height', this.screenHeight);
    // .call(this.zoom);

    d3.svg(imgUrl).then((d: any) => {
         this.svg.node().append(d.documentElement);
         if(this.activeComponent.length > 0){
           d3.select("#" + this.activeComponent).classed("test", true);
         }
    })
    //d3.select('#Layer_1').
  }

  test(e: any):void {
    // //console.log(d3.select(e.target).data(data));
    //console.log(e.target.tagName, this.lineGroup);
    var item = e.target.tagName;
    var itemId = e.target.parentNode.id;

  //   if (item === "rect" || item === "circle"){
      this.componentService.setConnector(itemId);
      this.schemData.changeActive(itemId);

    var t = e;
    this.update(t);
  }

  ngAfterViewInit() {
    const target = this.elRef.nativeElement.querySelector('#schematic-container');
    console.log(target);
    this.listener = this.renderer.listen(target, 'click', this.test.bind(this));

  }

  public update(t: any):void {

    var selectedItem = t.id;
    if(t.type === "click"){
      selectedItem = t.target.parentNode.id;
    } else {
      selectedItem = t.id;
    }


    var ele = selectedItem;

      let current: Array<string> = [];
    let nodel: any;


        current.push(ele);
        nodel = d3.select('svg').selectAll('g');

      nodel.each(function(d:any,i: number,nid: any){
        let resetClass = d3.select(nid[i]).selectAll('*');
        resetClass.each(function(d: any, i:number, rid: any){
          d3.select(rid[i]).classed("highlight", false);

        });
      });


        if(current.length > 0){
            for(var i = 0; i < current.length; i++){
              d3.select('#' + current[i]).selectAll('*').classed("highlight", true);

            }
        }

  } //end UPDATE



  //opens and closes the sidebar
  toggleSB(){
    this.isOpen = !this.isOpen;
    let sb = document.getElementById('sb-content');
    if(sb != null && !this.isOpen){
      sb.classList.add('closed');
    } else if(sb != null && sb.classList.contains("closed"))  {
      sb.classList.remove("closed")
    }
  }

}
