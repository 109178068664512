import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

// TEST GIT FORK UPSTREAM MAIN FOR KOMATSU

export class SchematicDataService {

  // for main schematic view - ultimately generates svg
  private imageSource = new BehaviorSubject('');
  currentImg = this.imageSource.asObservable();

  private connImage = new BehaviorSubject('');
  currentConnImg = this.connImage.asObservable();

  private activeComponent = new BehaviorSubject('');
  currentActive = this.activeComponent.asObservable();

  private compType = new BehaviorSubject('ghost');
  currentType = this.compType.asObservable();

  private activeModel = new BehaviorSubject('');
  currentModel = this.activeModel.asObservable();

  private activeViewType = new BehaviorSubject('');
  viewType = this.activeViewType.asObservable();

  private activeId = new BehaviorSubject('');
  currentId = this.activeId.asObservable();

  private activeUrl = new BehaviorSubject('');
  currentUrl = this.activeUrl.asObservable();

  //private connectedEvent: EventEmitter


  constructor() {

  }

  setViewType(type: string){
    this.activeViewType.next(type);
  }

  changeModel(model: any){
    this.activeModel.next(model);
  }

  changeId(id: any){
    this.activeId.next(id);
  }

  changeImage(image: string){
    this.imageSource.next(image);
  }

  changeActive(component: string){
    let c = component.trim();
    if(c.length === 0){
      c = "main";
    // } else if (c === this.currentId){
    //   c = "main";
    }
    console.log(c);
    this.activeComponent.next(c);
  }

  changeType(component: string){
    this.compType.next(component);
  }

  changeUrl(model: string, id?: string,type?: string){
    console.log(type);
    if(model && id){
      switch(type) {
        case 'video': {
          this.activeUrl.next('./assets/video/'+model+'/'+id);
          break;
        }
        case 'schematics': {
          this.activeUrl.next('./assets/'+model+'/'+id);
          break;
        }
        case 'componentId': {
          this.activeUrl.next('./assets/'+model);
          break;
        }
        default: {
          break;
        }
      }

    } else if (model && !id){
          this.activeUrl.next('./assets/'+model);
    }

  }

  changeConnImage(comp: string){
      this.connImage.next(comp+'.jpg');
  }



}
