import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

import { Connector, Info } from "../../interfaces/connector-data";
import { ComponentDataService } from '../../services/component-data.service';
import { SchematicDataService } from "../../services/schematic-data.service";

@Component({
  selector: 'app-component-content',
  templateUrl: './component-content.component.html',
  styleUrls: ['./component-content.component.scss']
})
export class ComponentContentComponent implements OnInit {
  @Input() name: any;

  componentContent: any;

  activeComponent: any;

  activeUrl: string = '';


  constructor(private componentService: ComponentDataService, private schemData: SchematicDataService) {
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.componentService.connContent.subscribe(info => {
       this.componentContent = info;
    });

    this.schemData.currentUrl.subscribe(current => this.activeUrl = current);
   }

  ngOnInit(): void {
    console.log(this.componentContent);
  }

}
