import { Component, EventEmitter, Output, ViewChild, ViewContainerRef, ComponentFactoryResolver, QueryList, ContentChildren, AfterContentInit } from '@angular/core';

import { Tab } from '../../interfaces/tab';
import { TabComponent } from './tab.component';
import { ComponentInfoComponent } from '../component-info/component-info.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {

  @Output() selected = new EventEmitter<any>();


  //324 dynamic tabs
  dynamicTabs: TabComponent[] = [];
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @ViewChild('dynamic', {read: ViewContainerRef}) dynamicPlaceholder: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {  }

  ngAfterContentInit(){
    const activeTabs = this.tabs.filter(tab => tab.active);

    if(activeTabs.length === 0){
      this.selectTab(this.tabs.first);
    }

  }

  openTab(title: string, template: any, data: any, isCloseable: boolean = false) {

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(TabComponent);

    let viewContainerRef = this.dynamicPlaceholder;

    const componentRef = viewContainerRef.createComponent(componentFactory);

    const instance: TabComponent = componentRef.instance as TabComponent;
    instance.title = title;
    instance.template = template;
    instance.dataContext = data;
    instance.isCloseable = isCloseable;

    this.dynamicTabs.push(componentRef.instance as TabComponent);
    // if(this.dynamicTabs.length == 2 ){
    //   this.selectTab(this.dynamicTabs[0]);
    // }
    //console.log(instance);

  }

  selectTab(tab: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => (tab.active = false));
    this.dynamicTabs.forEach(tab => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }

  //called to clear dynamicTabs array
  closeTabs(){
    for (let i = 0; i < (this.dynamicTabs.length); i++) {
      //this.closeTab(this.dynamicTabs[i]);
        this.dynamicTabs.splice(i);
        let viewContainerRef = this.dynamicPlaceholder;
        viewContainerRef.clear(i);
        //console.log(viewContainerRef);
      }
        // set tab index to 1st one
        this.selectTab(this.tabs.first);
  }

  closeTab(tab: TabComponent) {
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      if (this.dynamicTabs[i] === tab) {
        // remove the tab from our array
        this.dynamicTabs.splice(i, 1);

        // destroy our dynamically created component again
        let viewContainerRef = this.dynamicPlaceholder;
        viewContainerRef.clear(i);

        // set tab index to 1st one
        this.selectTab(this.tabs.first);
        break;
      }
    }
  }

  closeActiveTab() {
    const activeTabs = this.dynamicTabs.filter(tab => tab.active);
    if (activeTabs.length > 0) {
      // close the 1st active tab (should only be one at a time)
      this.closeTab(activeTabs[0]);
    }
  }

}
