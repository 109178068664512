import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { SchematicsViewComponent } from './pages/schematics-view/schematics-view.component';
import { CompIdComponent } from './pages/comp-id/comp-id.component';
import { VideoViewComponent } from './pages/video-view/video-view.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },

  // the following path should be removed - please check
  { path: 'schematics/:id', component: SchematicsViewComponent },
  { path: 'componentId/:model', component: CompIdComponent },

  { path: 'video/:model/:id', component: VideoViewComponent },
  { path: 'schematics/:model/:id', component: SchematicsViewComponent },
  // { path: 'schematics/:model/engine', component: SchematicsViewComponent },
  // { path: 'schematics/:model/:id', component: SchematicsViewComponent },

  // the following path is for models with engines - please fix
  { path: 'schematics/:model/:id/:engine', component: SchematicsViewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
