<div class="title">{{activeComponent}}</div>
<div *ngIf="componentContent" class="comp-data">
  <div *ngFor="let c of componentContent">
    <h2>{{c.tabName}}</h2>
    <p>{{c.content}}</p>
  </div>
  <!-- <table class="table table-striped">
    <thead>
      <th>Part</th>
      <th>M/F</th>
      <th class="small-col">POS/AWG</th>
      <th>Part No.</th>
    </thead>
    <tbody>
      <tr *ngFor="let info of componentInfo">
        <td>{{ info.part }}</td>
        <td>{{ info.gender }}</td>
        <td class="small-col">{{ info.pos }}</td>
        <td>{{ info.mfgId }}</td>
      </tr>
    </tbody>
  </table> -->

</div>