import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

// @ts-ignore
import * as d3 from 'd3';
import { SchematicDataService } from './schematic-data.service';


@Injectable({
  providedIn: 'root'
})
export class ComponentDataService {
  private infoArray: BehaviorSubject<Info[]> = new BehaviorSubject<Info[]>([]);
  connInfo = this.infoArray.asObservable();

  private contentArray: BehaviorSubject<Content[]> = new BehaviorSubject<Content[]>([]);
  connContent = this.contentArray.asObservable();

  private stepArray: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>([]);
  stepContent = this.stepArray.asObservable();

  private pinArray: BehaviorSubject<Pinout[]> = new BehaviorSubject<Pinout[]>([]);
  pinInfo = this.pinArray.asObservable();

  private groupArray: BehaviorSubject<Connector[]> = new BehaviorSubject<Connector[]>([]);
  groupInfo = this.groupArray.asObservable();

  private viewArray: BehaviorSubject<Connector[]> = new BehaviorSubject<Connector[]>([]);
  connView = this.viewArray.asObservable();

  // private clickedComp: BehaviorSubject = new BehaviorSubject<string>('');

  connectorArray: Connector[] = [];
  pinoutArray: Pinout[] = [];
  connInfoArray: Info[] = [];
  connContentArray: Content[] = [];
  stepContentArray: Step[] = [];
  groupInfoArray: Connector[] = [];
  activeUrl: any;

  currentSchematic?: string = '';
  model?: string = '';
  id: string = '';

  connViewArray: any;


  constructor(private http: HttpClient, private route: ActivatedRoute, private schemData: SchematicDataService) {
    this.schemData.currentUrl.subscribe(current => this.activeUrl = current);

    this.schemData.currentModel.subscribe(current => this.model = current);

    this.schemData.currentId.subscribe(current => this.id = current);
  }

  setConnector(d: any){
    let partId = d;
    //console.log(d);
    this.http.get(this.activeUrl + '/part-info-list.csv', {responseType: 'text'})
      .subscribe(
        data => {
          //console.log(data);
          let csvToRowArray = data.split("\n");
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            let row = csvToRowArray[index].split(",");
            let rowConn = row[1].toLowerCase().trim().replace(/\s|_/g, "-");
            //console.log(rowConn, partId);
              if(rowConn === partId){
                //console.log(row[0], row[1])
                this.connViewArray = new Connector( row[0], row[1].trim().replace(/\n|\r/g, ""));

              }
          }
          // console.log(this.connViewArray);
          //this.viewArray.next(this.connViewArray);
          },
          error => {
          console.log(error);

        }
      )
    //return connector;
    this.viewArray.next(this.connViewArray);
    //console.log(this.connViewArray, this.viewArray);

  }

  getViewConnector() {
    return of(this.viewArray);
  }

  setGroupData(group: any){

     if(this.groupInfoArray.length > 0){
       this.groupInfoArray = [];
     }
     this.http.get(this.activeUrl + '/part-info-list.csv', {responseType: 'text'})
       .subscribe(
         data => {
           let csvToRowArray = data.split("\n");
           for(let i = 0; i < group.length; i++){
             let te = group[i];
             console.log(te, group);
           for (let index = 1; index < csvToRowArray.length - 1; index++) {
             let row = csvToRowArray[index].split(",");
             let rowConn = row[1].toLowerCase().trim().replace(/\s|_/g, "-");

               if(rowConn === te){
                 console.log(rowConn);
                 this.groupInfoArray.push(new Connector(  row[0].trim().replace(/\n|\r/g, ""), row[1]));
               }
             }
           }
           },
           error => {
           //console.log(error);

         }
       )
       console.log(this.groupInfoArray);
       this.groupArray.next(this.groupInfoArray);
   }

  getGroupData(): Observable<Connector[]> {
      return of(this.connectorArray);
  }


  setComponentData(model: string){

    //in here we need to find out what connectors are available based on id (spyder: base, top)
     this.model = model;
     if(this.connectorArray.length > 0){
       this.connectorArray = [];
     }
     //console.log(model);
     // if(model === "spyder"){
     //   console.log("spyder", this.activeUrl);
     // }
     d3.csv(this.activeUrl + '/part-info-list.csv')

       .then(
         data => {
           console.log(data.length);
           //let csvToRowArray = data.split("\r");
           for (let index = 0; index < data.length; index++) {


             if(data){

               this.connectorArray.push(new Connector(  data[index].name!.trim().replace(/\n|\r/g, ""), data[index].id!.toString()));
             }
             // if(model === "spyder"){
             //    this.connectorArray.push(new Connector(  row[1], row[2]));
             // }else{
               // this.connectorArray.push(new Connector(  row[0].trim().replace(/\n|\r/g, ""), row[1]));
             //}
             this.connectorArray.sort(function(a,b){
               return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base'});
             })

           }

           },
           error => {
           console.log(error);

         }
       )

   }

  getComponentData(): Observable<Connector[]> {
      return of(this.connectorArray);
  }


  setInfo(connector: any){
    console.log(connector);
    if (connector.id){
      var connectorName = connector.id.toLowerCase().trim().replace(/\s|_/g, "-");
    }else{
      var connectorName = connector.toLowerCase().trim().replace(/\s|_/g, "-");
    }

    if(this.connInfoArray.length > 0){
      this.connInfoArray = [];
    }

    d3.csv(this.activeUrl + '/connector-item-list.csv')
      .then(
        data => {
          for (let index = 1; index < data.length - 1; index++) {
            //console.log(data);
            // let row = csvToRowArray[index].split(",");
            let rowConn = data[index].connector!.toLowerCase().trim().replace(/\s|_/g, "-");
            //
            if(rowConn === connectorName){
                this.connInfoArray.push(new Info( data[index].connector!, data[index].part!, data[index].gender!, data[index].pos!, data[index].mfgId!));
            } //'connector', 'part', 'gender', 'pos', 'mfgId'
          }
          },
          error => {
          console.log(error);
        }
      )
      this.infoArray.next(this.connInfoArray);
  }

  getInfo() {
      return of(this.infoArray);
  }

  setPinout(connector: any): void {
    if (connector.id){
      var connectorName = connector.id.toLowerCase().trim().replace(/\s|_/g, "-");
    }else{
      var connectorName = connector.toLowerCase().trim().replace(/\s|_/g, "-");
    }

    if(this.pinoutArray.length > 0){
      this.pinoutArray = [];
    }
    d3.csv(this.activeUrl + '/pinout-info-list.csv')
      .then(
        data => {
          for (let index = 1; index < data.length - 1; index++) {

            let rowConn = data[index].connector!.toLowerCase().trim().replace(/\s|_/g, "-");
            if(rowConn === connectorName){
                this.pinoutArray.push(new Pinout( data[index].connector!, data[index].pin!, data[index].rep!, data[index].wireColor!));
            } //connector: 'yrs', pin: '4', rep: 'S12V', wireColor: 'RD/OG-20'

          }
          },
          error => {
          console.log(error);

        }
      )
      this.pinArray.next(this.pinoutArray);
  }

  getPinout(name: string){
    return of(this.pinoutArray);
  }
  //Video/Comp ID tab infoormation
  setContent(connector: any){
    //console.log(connector);
    if (connector.id){
      var connectorName = connector.id.toLowerCase().trim().replace(/\s|_/g, "-");
    }else{
      var connectorName = connector.toLowerCase().trim().replace(/\s|_/g, "-");
    }

    if(this.connContentArray.length > 0){
      this.connContentArray = [];
    }

    d3.csv(this.activeUrl + '/tab-content.csv')
      .then(
        data => {
          for (let index = 0; index < data.length; index++) {
            //console.log(data[index]);
            // let row = csvToRowArray[index].split(",");
            let rowConn = data[index].id!.toLowerCase().trim().replace(/\s|_/g, "-");
            //
            if(rowConn === connectorName){
                this.connContentArray.push(new Content( data[index].tab!, data[index].content!));
            } //'connector', 'part', 'gender', 'pos', 'mfgId'
          }
          },
          error => {
          console.log(error);
        }
      )
      console.log(this.connContentArray);
      this.contentArray.next(this.connContentArray);
  }

  getContent() {
      return of(this.contentArray);
  }
  // step-by-step data
  setStepData(scenario: any){
    console.log(this.activeUrl);
    let s = scenario.trim().replace(/[A-Z]/g, (str:any) => '-' + str.toLowerCase());
    console.log(this.activeUrl + '/'+ s +'-steps.csv');
    if(this.stepContentArray.length > 0){
      this.stepContentArray = [];
    }

    d3.csv(this.activeUrl + '/'+ s +'-steps.csv')
      .then(
        data => {
          if(data){
            for (let index = 0; index < data.length; index++) {
              //console.log(data[index]);
              // let row = csvToRowArray[index].split(",");
              //let rowConn = data[index].id!.toLowerCase().trim().replace(/\s|_/g, "-");
              //

                  this.stepContentArray.push(new Step( parseInt(data[index].step!), data[index].text!,parseInt(data[index].timeStart!), parseInt(data[index].timeEnd!)));
               //'connector', 'part', 'gender', 'pos', 'mfgId'
               }
               }
          },
          error => {
              console.log(error);
          }
      )


      this.stepArray.next(this.stepContentArray);
  }

  getStepData() {
      return of(this.stepArray);
  }

}



export class Connector {
  name: string;
  id: string;

  constructor(  name: string, id: string)
  {
    this.name = name;
    this.id = id;
  }
}

export class Info {
  name: string;
  part: string;
  gender: string;
  pos: string;
  mfgId: string;

  constructor(  name: string, part: string, gender: string, pos: string, mfgId: string)
  {
    this.name = name;
    this.part = part;
    this.gender = gender;
    this.pos = pos;
    this.mfgId = mfgId;
  }
}

  export class Pinout {
    name: string;
    pin: string;
    rep: string;
    wireColor: string;

    constructor(  name: string, pin: string, rep: string, wireColor: string)
    {
      this.name = name;
      this.pin = pin;
      this.rep = rep;
      this.wireColor = wireColor;
    }
}

export class Content {
  tabName: string;
  content: string;


  constructor(  tab: string, content: string)
  {
    this.tabName = tab;
    this.content = content;

  }
}
export class Step {
  step: number;
  text: string;
  timeStart: number;
  timeEnd: number;


  constructor(  step: number, text: string, timeStart: number, timeEnd: number)
  {
    this.step = step;
    this.text = text;
    this.timeStart = timeStart;
    this.timeEnd = timeEnd;
  }
}
