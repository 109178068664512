<div #box *ngIf="showLegend();" id="legend" [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'" [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)" (window:mouseup)="setStatus($event, 0)">
  <div id="legend-title" (click)="toggleLegend()">
    <h4>Line Types</h4>
    <span id="open-icon" class=""></span>
  </div>
  <div id="legend-content" class="">
    <div class="legend-row"><span class="legend-row-text">55 / 60 Volts</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(255, 165, 0);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">12 Volts Switched</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(255, 192, 203);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">12 Volts Unswitched</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(255, 0, 0);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">5 or 3.3 Volts</span><span class="legend-row-color thin" style="border-style: solid; border-color: rgb(255, 0, 0);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">Digital Signal</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(0, 255, 255);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">Power Control</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(165, 42, 42);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">Analog Signal</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(0, 0, 255);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">Communication</span><span class="legend-row-color" style="border-style: dashed; border-color: rgb(0, 128, 0);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">A-D Ground</span><span class="legend-row-color thin" style="border-style: solid; border-color: rgb(0, 0, 0);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">Power Ground</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(0, 0, 0);"></span></div>
    <hr>
    <div class="legend-row"><span class="legend-row-text">AC Voltage</span><span class="legend-row-color" style="border-style: solid; border-color: rgb(255, 255, 0);"></span></div>
  </div>
</div>