import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

import { SchematicDataService } from '../../services/schematic-data.service';

@Component({
  selector: 'app-schematics',
  templateUrl: './schematics.component.html',
  styleUrls: ['./schematics.component.scss']
})
export class SchematicsComponent implements OnInit {

  @Input('component') activeComponent: string = '';

  constructor(private schemData: SchematicDataService) { }

  ngOnInit(): void {
    console.log(this.activeComponent);
  }

  // ngAfterViewInit() {
  //   const target = this.elRef.nativeElement.querySelector('#schematic-container');
  //   this.listener = this.renderer.listen(target, 'click', this.updateSelected.bind(this));
  // }
  //
  //
  //   updateSelected(e: any):void {
  //     // //console.log(d3.select(e.target).data(data));
  //     //console.log(e.target.tagName, this.lineGroup);
  //     var item = e.target.tagName;
  //     var itemId = e.target.id;
  //     //console.log(itemId.length);
  //
  //     if (item === "rect" || item === "circle"){
  //       this.componentService.setGroupData('');
  //       this.clearSide = e.target.id;
  //       this.componentService.setConnector(e.target.id);
  //       this.schemData.changeActive(e.target.id);
  //       this.componentService.setInfo(e.target.id);
  //       this.componentService.setPinout(e.target.id);
  //     } else if (item === "line" || item === "polyline"){
  //       this.clearSide = "group";
  //       this.schemData.changeActive("main");
  //       //console.log(this.lineGroup);
  //     } else if(itemId.length === 0){
  //           //tell sidebar to close all tabs
  //           this.clearSide = '';
  //
  //           this.schemData.changeActive('main');
  //     }
  //
  //
  //     var t = e;
  //     this.update(t);
  //   }

//   private createSVG(): void {
//    this.svg = d3.select('#stuff');
//
//    d3.select("svg").remove();
//
//    this.componentService.getComponentData().subscribe(connectors => this.connector = connectors);
//
//         var imgUrl =  this.image.slice(0, this.image.indexOf(".")) + '.svg';
//         this.createImage(imgUrl);
//
//     } // end createSVG
//
//
// public createImage(imgUrl: string) {
//   console.log(imgUrl);
//   this.svg = d3.select("#schematic-container")
//   .attr('width', this.screenWidth)
//   .attr('height', this.screenHeight)
//   .call(this.zoom);
//
//   d3.xml(imgUrl).then((xml: any) => {
//        this.svg.node().append(xml.documentElement);
//        if(this.activeComponent.length > 0){
//          d3.select("#" + this.activeComponent).classed("test", true);
//        }
//   })
//   //d3.select('#Layer_1').
// }  // end createImage
//
//
//   public zoomed({transform}:any){
//     //console.log(transform.invert);
//
//     d3.select('#Layer_1').attr("transform", transform);
//     d3.select('#Components').attr("transform", transform);
//     d3.select('#Wires').attr("transform", transform);
//     d3.select('#Background').attr("transform", transform);
//     d3.select('#Base').attr("transform", transform);
//     d3.select('#Text').attr("transform", transform);
//   }
//
// public update(t: any):void {
//   var selectedItem;
//   if(t.type === "click"){
//     selectedItem = t.target;
//   } else {
//     selectedItem = t;
//   }
//
//   var ele = selectedItem.tagName;
//   let current: Array<string> = [];
//   let nodel: any;
//   let nodels = ['circle', 'rect', 'g'];
//   if(ele === 'line' || ele === 'polyline') {
//       current.push(selectedItem.parentNode.id);
//       nodel = d3.select('#' + selectedItem.parentNode.parentNode.id).selectAll('g');
//       let rects = d3.select('#Components').selectAll('rect');
//
//       let lines = d3.select('#' + selectedItem.parentNode.id).selectAll('*');
//       if(lines != null && rects != null) {
//       lines.each(function(d:any,i: number, l: any){
//         if(l[i]){
//           let r1 = l[i].getBoundingClientRect();
// console.log(r1);
//           rects.each(function(d:any,i: number, r: any){
//             if(r[i]){
//               let r2 = r[i].getBoundingClientRect();
//               //checks if lines link with component boxes - crosses 1 side
//               if(!(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top)) {
//                 //checks if line intersects component  - crosses 2 sides of component or is too close to highlighted line
//                 if(!((r2.left > r1.left && r2.right < r1.right) || (r2.top > r1.top && r2.bottom < r1.bottom))) {
//                   if(current.indexOf(r[i].id) === -1){
//                     current.push(r[i].id);
//                   }
//                 }
//                }
//             }
//           }); // end rect.each
//         }
//       }); // end lines.each
//     } // end if not null
//     console.log(current);
//       this.buildConnectedMenu(current);
//
//   } else {
//     if(selectedItem.id.length > 0){
//       current.push(selectedItem.id);
//       nodel = d3.selectAll(ele);
//     }
//
//   }
//   // removes class from all
//   for( var i = 0; i < nodels.length; i++){
//       let base = d3.select('#Base');
//         if(nodels[i] === 'g'){
//           nodel = d3.select('#Wires').selectAll(nodels[i]);
//           wires(nodel);
//         } else if(nodels[i] === 'circle') { //remove this when model svg is updated
//           nodel = d3.selectAll(nodels[i]);
//           reset(nodel);
//         } else if(nodels[i] === 'rect'){
//           nodel = d3.select('#Components').selectAll(nodels[i]);
//           reset(nodel);
//         }
//
//       }
//       function wires(n: any){
//         reset(nodel);
//         n.each(function(d:any,i: number,nid: any){
//           d3.select(nid[i]).classed("test", false);
//           d3.select(nid[i]).classed("dimmed", false);
//           if(current.length > 0){
//             for(var j = 0; j < current.length; j++){
//               if(nid[i].id === current[j]){
//
//                 d3.select(nid[i]).classed("test", true);
//                 break;
//               } else {
//                 d3.select(nid[i]).classed("dimmed", true);
//               }
//
//
//
//               }
//           }
//         });
//       }
//
//       function reset(nodel: any){
//         nodel.each(function(d:any,i: number,nid: any){
//           d3.select(nid[i]).classed("test", false);
//           d3.select(nid[i]).classed("dimmed", false);
//         });
//       }
//
//       if(current.length > 0){
//           for(var i = 0; i < current.length; i++){
//             d3.select('#' + current[i]).classed("test", true);
//
//           }
//       }
//
// } //end UPDATE

}
