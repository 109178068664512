import { Component, Input, Output, EventEmitter, AfterViewInit, AfterViewChecked, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

// @ts-ignore
import * as d3 from 'd3';

import { Connector } from "../../interfaces/connector-data";
import { ComponentDataService } from '../../services/component-data.service';
import { SchematicDataService } from '../../services/schematic-data.service';

@Component({
  selector: 'app-component-menu',
  templateUrl: './component-menu.component.html',
  styleUrls: ['./component-menu.component.scss']
})
export class ComponentMenuComponent {

    @Input() connector: any;
    @Output() openCompInfo = new EventEmitter<any>();

    components: Connector[] = [];

    activeComponent: any;

    selectedConnector: any;

  constructor(private componentService: ComponentDataService, private schemData: SchematicDataService, private route: ActivatedRoute, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.schemData.currentActive.subscribe(
      (current) => {this.activeComponent = current; this.scrollCall();});
    this.componentService.connView.subscribe(connectors => this.selectedConnector = connectors);

  }

  ngAfterViewChecked(){
//     const divScroll = document.getElementById('componentMenu');
//     var divTop = 0;
//     if(divScroll){
// divTop = divScroll.offsetTop;}
//     const itemToScroll = document.querySelector("."+this.activeComponent);
//
//
//     if (itemToScroll){
//
//     itemToScroll.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
//      }

  }

  scrollCall(){
    const divScroll = document.getElementById('componentMenu');
    var divTop = 0;
    if(divScroll){
divTop = divScroll.offsetTop - 50;}
    const itemToScroll = document.querySelector("."+this.activeComponent);

    console.log(this.activeComponent);
    if (itemToScroll){

    itemToScroll.scrollIntoView({behavior: 'smooth', block: 'center'});
     }
  }

}
