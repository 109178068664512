<div id="wrapper">
  <app-nav-menu [model]="currentModel" [id]="currentSchematic"></app-nav-menu>
  <!-- <app-legend [width]="1000" [height]="600" [left]="100" [top]="100"></app-legend> -->
  <div id="schematic-container">
    <!-- <app-schematics [component]="activeType"></app-schematics> -->
    <!-- <app-component-popup [hidden]="!activeComponent"></app-component-popup> -->
    <!-- <div id="btn-container">
      <div id="zoom-out" class="zoom-button" (click)="zoomOut()"></div>
      <div id="zoom-in" class="zoom-button" (click)="zoomIn()"></div>
      <div id="recenter" class="zoom-button" (click)="zoomCenter()"></div>
    </div> -->
  </div>


  <div id="sb-content" class="">
    <app-comp-id-sidebar (imgEle)='updateImg($event)'></app-comp-id-sidebar>
    <span id="toggle-sidebar" (click)="toggleSB()"></span>
  </div>
</div>