import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

import { Connector, Pinout } from "../../interfaces/connector-data";
import { ComponentDataService } from '../../services/component-data.service';
import { SchematicDataService } from "../../services/schematic-data.service";

@Component({
  selector: 'app-component-pinout',
  templateUrl: './component-pinout.component.html',
  styleUrls: ['./component-pinout.component.scss']
})
export class ComponentPinoutComponent implements OnInit {
  @Input() name: any;

  pinoutInfo: Pinout[] = [];
  activeComponent: any;
  activeModel: string = '';
  activeUrl: string = '';
  compName: Connector[] = [];
  partName: string = '';


  constructor(private componentService: ComponentDataService, private schemData: SchematicDataService) {
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);
    this.componentService.pinInfo.subscribe(info => this.pinoutInfo = info);
    this.componentService.connView.subscribe(comp => this.compName = comp);
    this.schemData.currentModel.subscribe(current => this.activeModel = current);
        this.schemData.currentUrl.subscribe(current => this.activeUrl = current);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

      console.log(this.compName);
      //this.partName = this.compName.name;
  }


}
