import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';

import { SchematicDataService } from "../../services/schematic-data.service";

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit, AfterViewInit {
  @Input('width') width!: number;
  @Input('height') height!: number;
  @Input('left') left!: number;
  @Input('top') top!: number;

  @ViewChild("box") box!: ElementRef;

  activeComponent: any;
  activeType: any;

  legendIsOpen = true;

  // private mousePosition: any;
  // private dragOffset: any;
  // private isDown: any;
  private legend: any;
  public screenWidth: number = 0;
  public screenHeight: number = 0;

  boxPosition!: { left: number, top: number};
  containerPos!: {left: number, top: number, right: number, bottom: number};
  mouse!: {x: number, y: number};
  status: Status = Status.OFF;
  mouseClick!: {x:number, y:number, left: number, top:number};

  constructor(private schemData: SchematicDataService) {
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);

    this.schemData.currentType.subscribe(current => this.activeType = current);
    //
    // this.isDown = false;
    // this.dragOffset = [0, 0];
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

  }

  ngAfterViewInit(){

  }

  loadBox(){
    if(this.box){
      const {left, top} = this.box.nativeElement.getBoundingClientRect();
      this.boxPosition = {left, top};

      this.loadContainer();
    }
  }

  loadContainer(){
    const left = 0;
    const top = 0;
    // const left = this.boxPosition.left - this.left;
    // const top = this.boxPosition.top - this.top;
    const right = this.screenWidth;
    const bottom = this.screenHeight;
    this.containerPos = {left, top, right, bottom};
  }

  setStatus(event: MouseEvent, status: number){
    if(status === 1) this.toggleLegend();
    else if(status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
    else this.loadBox();
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY};
    if(this.status === 2) this.move();
  }

  private move(){
    if(this.moveCondMeet()){
      this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
      this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
    }
  }

  private moveCondMeet(){
    const offsetLeft = this.mouseClick.x - this.boxPosition.left;
    const offsetRight = this.width - offsetLeft;
    const offsetTop = this.mouseClick.y - this.boxPosition.top;
    const offsetBottom = this.height - offsetTop;
    //console.log(offsetLeft, this.mouseClick.x - this.boxPosition.left, this.mouseClick.x, this.boxPosition.left, this.containerPos.left)
    return (
      this.mouse.x > this.containerPos.left + offsetLeft &&
      this.mouse.x < this.containerPos.right - offsetRight &&
      this.mouse.y > this.containerPos.top + offsetTop &&
      this.mouse.y < this.containerPos.bottom - offsetBottom
      );
  }

  showLegend(){
    if(this.activeType === 'schematic') {
      this.loadBox();
      return true;

    }else{
      return false;
    }
  }

  toggleLegend() {
    let leg = document.getElementById('legend-content');
    if(leg != null && this.legendIsOpen){
      leg.classList.add('closed');
    } else if(leg != null && leg.classList.contains('closed')) {
      leg.classList.remove('closed');
    }

    this.legendIsOpen = !this.legendIsOpen;
  }


}
