<div id="nav">
  <ul [ngSwitch]="model">
    <li class="home"><a routerLink="/"></a></li>
    <li *ngSwitchCase="'defender'" class="nav-button make">Defender</li>
    <li *ngSwitchCase="'defender'" class="nav-button make">HD5</li> <!-- Insert model name (ie: Defender) -->
    <li *ngSwitchCase="'ryker'" class="nav-button make">Ryker</li>
    <li *ngSwitchCase="'ryker'" class="nav-button make">Ryker 2019</li>
    <!-- <li class="nav-button model">{{id}}</li>-->
    <!-- Insert model id (ie: HD5) -->
    <li *ngSwitchCase="'planetaryGear'" class="nav-button make">Component ID</li>
    <li *ngSwitchCase="'planetaryGear'" class="nav-button make">Planetary Gear</li>
    <!-- videos -->
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button make">Schematic Animations</li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button make">Cranking Circuit</li>
  </ul>

  <hr />

  <ul [ngSwitch]="model">
    <li *ngSwitchCase="'defender'" class="nav-button"><a routerLink="/schematics/defender/engine" routerLinkActive="activeButton">Engine</a></li>
    <li *ngSwitchCase="'defender'" class="nav-button"><a routerLink="/schematics/defender/hd5" routerLinkActive="activeButton">Harness</a></li>

    <li *ngSwitchCase="'ryker'" class="nav-button"><a routerLink="/schematics/ryker/engine" routerLinkActive="activeButton">Engine</a></li>
    <li *ngSwitchCase="'ryker'" class="nav-button"><a routerLink="/schematics/ryker/ryker-2019" routerLinkActive="activeButton">Harness</a></li>

    <li *ngSwitchCase="'planetaryGear'" class="nav-button"><a routerLinkActive="activeButton">Component ID</a></li>

    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a routerLink="/video/crankingCircuit/basic" routerLinkActive="activeButton">Standard</a></li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a routerLink="/video/crankingCircuit/basic-plus" routerLinkActive="activeButton">Standard Plus*</a></li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a routerLink="/video/crankingCircuit/advanced" routerLinkActive="activeButton">Advanced</a></li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a routerLink="/video/crankingCircuit/advanced-plus" routerLinkActive="activeButton">Advanced Plus*</a></li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a routerLink="/video/crankingCircuit/basic-custom" routerLinkActive="activeButton">Standard Custom*</a></li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a routerLink="/video/crankingCircuit/basic-step" routerLinkActive="activeButton">Step-by-Step</a></li>
    <li *ngSwitchCase="'crankingCircuit'" class="nav-button"><a href="https://extras.almoninc.com/interactive-schematics/" routerLinkActive="activeButton" target="_blank">Stand Alone - External</a></li>

  </ul>
</div>