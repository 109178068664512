<div id="wrapper">
  <app-nav-menu [model]="currentModel" [id]="currentSchematic"></app-nav-menu>
  <div id="schematic-container" [ngClass]="{'custom':isCustom, 'step': isStep}">
    <!-- *ngIf="!isCustom; else custom" -->
    <!-- <video width="640" height="365" controls #video (click)="playVideo($event)">
      <source src="./assets/almon-crankin-circuit-basic.mp4" type="video/mpeg">
      Your browser does not support the video element. Kindly update it to latest version.
    </video> -->
    <video #myvideo *ngIf="!isCustom && !isStep" id="myVid" playsinline src="{{activeUrl}}/almon-crankin-circuit-{{currentSchematic}}.mp4" width="640" height="365"> </video>

    <div *ngIf="!isCustom && !isStep" id="play-container">
      <div id="play" class="vid-button" (click)="buttonEvent($event)">
        Play
      </div>
      <!-- <div id="pause" class="vid-button" (click)="buttonEvent($event)">
        Pause
      </div> -->
    </div>



    <video #myvideo *ngIf="isCustom" id="{{currentSchematic}}" playsinline src="{{activeUrl}}/almon-crankin-circuit-{{currentSchematic}}.mp4" width="640" height="365"> </video>
    <div id="btn-container" *ngIf="isCustom">
      <div id="systemInit" class="vid-button" (click)="buttonEvent($event)">
        Voltage Available
      </div>
      <div id="start" class="vid-button" (click)="buttonEvent($event)">
        Turn Key to Start
      </div>
      <div id="neutral" class="vid-button" (click)="buttonEvent($event)">
        Engage Neutral
      </div>
      <div id="park" class="vid-button" (click)="buttonEvent($event)">
        Engage Park Brake
      </div>
    </div>

    <video #myvideo *ngIf="isStep" id="{{currentSchematic}}" playsinline controlsList="nodownload nofullscreen noremoteplayback" src="{{activeUrl}}/almon-crankin-circuit-{{currentSchematic}}.mp4" width="640" height="365"> </video>

    <div class="step-container" *ngIf="isStep">
      <div id="step-bar" class="down">
        <div class="title-text" data-lang-child="title">Cranking Circuit</div><span [hidden]="step === 0" class="step-num-text" [style.display]="step === 0? 'none': 'inline-block'">Step<span class="step-number">: {{step}}</span></span>
        <div class="text" #stepText>
          Press the <span class="bold">Next</span> button to begin.
        </div>
        <!-- <div class="initial-text hidden" data-lang-child="text">

        </div> -->
      </div>
    </div>

    <div id="btn-container" *ngIf="isStep">

      <div class="step-box">
        <div id="first" class="icon-button cap left" (click)="buttonEvent($event)"><img src="./assets/images/first-step.svg" height="50" width="50" /></div>
        <div id="previous" class="icon-button" (click)="buttonEvent($event)"><img src="./assets/images/previous-step.svg" height="50" width="50" /></div>
        <div id="step-text" #stepNums>---</div>
        <div id="next" class="icon-button" (click)="buttonEvent($event)"><img src="./assets/images/next-step.svg" height="50" width="50" /></div>
        <div id="replay" class="icon-button cap right" (click)="buttonEvent($event)"><img src="./assets/images/replay-step.svg" height="28" width="28" /></div>
      </div>

    </div>

  </div>

  <div [hidden]="!isInteractive" id="sb-content" class="">
    <!-- <app-comp-id-sidebar (imgEle)='updateImg($event)'></app-comp-id-sidebar> -->
    <app-sidebar (imgEle)='updateImg($event)'></app-sidebar>
    <span id="toggle-sidebar" (click)="toggleSB()"></span>
  </div>
</div>