<div class="title">Connectors</div>
<div *ngIf="componentInfo" class="comp-data">

  <!-- <h2>{{name}}</h2> -->
  <table class="table table-striped">
    <thead>
      <th>Part</th>
      <th>M/F</th>
      <th class="small-col">POS/AWG</th>
      <th>Part No.</th>
    </thead>
    <tbody>
      <tr *ngFor="let info of componentInfo">
        <td>{{ info.part }}</td>
        <td>{{ info.gender }}</td>
        <td class="small-col">{{ info.pos }}</td>
        <td>{{ info.mfgId }}</td>
      </tr>
    </tbody>
  </table>
  <div class="infoImg">
    <img class="info-connector" src="{{activeUrl}}/pin-out-imgs-front/{{activeComponent}}.jpg" alt="Connector front view">
    <img class="info-connector" src="{{activeUrl}}/pin-out-imgs-back/{{activeComponent}}.jpg" alt="Connector front view">
  </div>
</div>