import { Component, OnInit } from '@angular/core';

import { SchematicDataService } from "../../services/schematic-data.service";

@Component({
  selector: 'app-component-popup',
  templateUrl: './component-popup.component.html',
  styleUrls: ['./component-popup.component.scss']
})
export class ComponentPopupComponent implements OnInit {

  activeComponent: any;
  activeType: any;
  currentSchematic: string = '';

  activeModel: string = '';
  activeUrl: string = '';


  constructor( private schemData: SchematicDataService ) {
    this.schemData.currentActive.subscribe(current => this.activeComponent = current);

    this.schemData.currentType.subscribe(current => this.activeType = current);
    this.schemData.currentModel.subscribe(current => this.activeModel = current);

        this.schemData.currentUrl.subscribe(current => this.activeUrl = current);
  }

  ngOnInit(): void {
  }

  showComp(){
    if(this.activeComponent != 'main' && this.activeType === 'ghost') {
      return true;
    }else{
      return false;
    }
  }

}
