import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'interactive-schematics-v2';
  currentRoute: string;

  constructor(private router: Router) {
    this.currentRoute = '';

    this.router.events.subscribe((event: Event) => {
      if(event instanceof NavigationStart) {
        console.log('Route change detect');
        this.currentRoute = event.url;
        console.log(event);
      }
      if(event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        console.log(event);
      }
      if(event instanceof NavigationError){
        console.log(event.error);
      }
    })
  }
}
