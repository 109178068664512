import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { Connector } from "../../interfaces/connector-data";
import { ComponentDataService } from '../../services/component-data.service';
import { SchematicDataService } from '../../services/schematic-data.service';

@Component({
  selector: 'app-component-group',
  templateUrl: './component-group.component.html',
  styleUrls: ['./component-group.component.scss']
})
export class ComponentGroupComponent implements OnInit {
  //@Input() connector: any;
  @Output() openCompInfo = new EventEmitter<any>();

  activeGroup: any;

  constructor(private componentService: ComponentDataService, private schemData: SchematicDataService) { }

  ngOnInit(): void {
        this.componentService.groupInfo.subscribe(components => this.activeGroup = components);
  }

}
