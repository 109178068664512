import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SchematicsComponent } from './components/schematics/schematics.component';
import { SchematicsViewComponent } from './pages/schematics-view/schematics-view.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ComponentMenuComponent } from './components/component-menu/component-menu.component';
import { ComponentInfoComponent } from './components/component-info/component-info.component';
import { ComponentPinoutComponent } from './components/component-pinout/component-pinout.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab.component';
import { WireColorPipe } from './wire-color.pipe';
import { ComponentHelpComponent } from './components/component-help/component-help.component';
import { ComponentPopupComponent } from './components/component-popup/component-popup.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { ComponentGroupComponent } from './components/component-group/component-group.component';
import { LegendComponent } from './components/legend/legend.component';
import { CompIdComponent } from './pages/comp-id/comp-id.component';
import { CompIdSidebarComponent } from './components/comp-id-sidebar/comp-id-sidebar.component';
import { VideoViewComponent } from './pages/video-view/video-view.component';
import { ComponentContentComponent } from './components/component-content/component-content.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SchematicsComponent,
    SchematicsViewComponent,
    SidebarComponent,
    ComponentMenuComponent,
    ComponentInfoComponent,
    ComponentPinoutComponent,
    TabsComponent,
    TabComponent,
    WireColorPipe,
    ComponentHelpComponent,
    ComponentPopupComponent,
    NavMenuComponent,
    ComponentGroupComponent,
    LegendComponent,
    CompIdComponent,
    CompIdSidebarComponent,
    VideoViewComponent,
    ComponentContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' },
        { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
