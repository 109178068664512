import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SchematicDataService } from '../../services/schematic-data.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  @Input('model') model: string = '';
  @Input('id') id: string = '';

  activeRoute!: Observable<any>; //does not currently hold anything
  //model: any;


  constructor(private route: ActivatedRoute, private schemData: SchematicDataService) { }

  ngOnInit(): void {
    console.log(this.activeRoute, this.model, this.id);

  }



}
