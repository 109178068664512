import { Component, Input, OnInit } from '@angular/core';

import { Tab } from '../../interfaces/tab';

// import { TabsComponent } from './tabs.component';
import { SchematicDataService } from "../../services/schematic-data.service";


@Component({
  selector: 'app-tab',
  styles: [
    `
    :host {
      height: 500px;
      background-color: #fff;
      width: 100px;
    }

  `
  ],
  template: `
  <div [hidden]="!active" class="pane">
    <ng-content></ng-content>
    <ng-container *ngIf="template"
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ component: dataContext }"
    >
    </ng-container>
  </div>
  `
})
export class TabComponent {

  @Input('tabTitle') title: string = '';
  @Input() active: boolean = false;
  @Input() isCloseable: boolean = false;
  @Input() template: any;
  @Input() dataContext: any;

  ngOnInit() {
    console.log(this.dataContext);
  }

}
